export default [
    // // 登录
    // {
    //     path: '/',
    //     name: 'Login',
    //     component: () => import('../views/login/Login')
    // },
    // 首页
    {
      path: "/",
      name: "Home",
      redirect: "/home",
      meta: {
        label: "CIM物联中心",
      },
      component: () => import("@/views/Home"),
      children: [
        {
          path: "/home",
          name: "Index",
          meta: {
            label: "平台概览",
            desc: "CIM物联中心概览页提供物联设备连接情况概览",
            isBack: false,
            title: "平台概览",
          },
          component: () => import("@/views/dashboard/generalization/Index"),
        },
        // 设备管理-产品列表
        {
          path: "/product-list",
          name: "product-List",
          meta: {
            label: "设备管理-产品列表",
            desc:
              "产品定义为具有同样属性的物联设备的集合，在产品列表中可以进行对物联设备的物模型管理。",
            isBack: false,
            title: "产品列表",
          },
          component: () =>
            import("@/views/dashboard/equipmentManagement/productList/Index"),
        },
        // 设备管理-产品详情
        {
          path: "/product-list/details",
          name: "Product-Details",
          meta: {
            label: "产品详情",
            desc: "提供产品详情编辑及物模型设置功能",
            isBack: true,
            title: "产品详情",
            parentRout: {
              label: "设备管理-产品列表",
              path: "/product-list",
            },
          },
          component: () =>
            import(
              "@/views/dashboard/equipmentManagement/productList/ProductDetails"
            ),
          props: (route) => ({ query: route.query.id }),
        },
        // 设备管理-设备列表
        {
          path: "/equip-list",
          name: "Equip-List",
          meta: {
            label: "设备管理-设备列表",
            desc: "物联设备管理单元提供对物联设备的增、删、改、查管理能力",
            isBack: false,
            title: "设备列表",
          },
          component: () =>
            import("@/views/dashboard/equipmentManagement/equipList/Index"),
        },
        // 设备管理-设备详情
        {
          path: "/equip-list/details",
          name: "Equip-Details",
          meta: {
            label: "设备详情",
            desc: "设备详情页提供设备上报数据的查看和设备信息的编辑功能",
            isBack: true,
            title: "设备详情",
            parentRout: {
              label: "设备管理-设备列表",
              path: "/equip-list",
            },
          },
          component: () =>
            import(
              "@/views/dashboard/equipmentManagement/equipList/EquipDetails"
            ),
          props: (route) => ({ query: route.query.id }),
        },
        // 设备分组-设备列表
        {
          path: "/equip-group",
          name: "Equip-group",
          meta: {
            label: "设备管理-分组管理",
            desc:
              "分组管理提供了对设备场景化的管理方式，通过分组可以实现设备批量监测和联动",
            isBack: false,
            title: "分组管理",
          },
          component: () =>
            import("@/views/dashboard/equipmentManagement/equipGroup/index.vue"),
        },
        // 设备分组-设备详情
        {
          path: "/equip-group/details",
          name: "Equip-Group-Details",
          meta: {
            label: "分组详情",
            desc:
              "分组管理提供了对设备场景化的管理方式，通过分组可以实现设备批量监测和联动",
            isBack: true,
            title: "分组详情",
            parentRout: {
              label: "设备管理-分组管理",
              path: "/equip-group",
            },
          },
          component: () =>
            import(
              "@/views/dashboard/equipmentManagement/equipGroup/EquipGroupDetails"
            ),
          props: (route) => ({ query: route.query.id }),
        },
        // 设备接入-标准协议接入概况
        {
          path: "/access-view",
          name: "Access-View",
          meta: {
            label: "设备接入-标准协议接入",
            desc: "按照数地物联中心提供的标准协议进行物联产品接入",
            isBack: false,
            title: "标准协议接入",
          },
          component: () =>
            import("@/views/dashboard/equipmentAccess/standardProtocol/Index"),
        },
        // 设备接入-泛协议接入概况
        {
          path: "/extensive-access",
          name: "Extensive-Access",
          meta: {
            label: "设备接入-泛协议接入",
            desc:
              "泛协议接入允许用户通过自定义物模型结合定制实例的方式进行非标准协议的设备接入",
            isBack: false,
            title: "泛协议接入",
          },
          component: () =>
            import("@/views/dashboard/equipmentAccess/extensiveProtocol/Index"),
        },
        // 运维监控-设备日志
        {
          path: "/equip-log",
          name: "Equip-Log",
          meta: {
            label: "运维监控-设备日志",
            desc: "提供数据库查询工具以及设备日志的查看功能",
            isBack: false,
            title: "设备日志",
          },
          component: () =>
            import("@/views/dashboard/operationControl/equipmentLog/Index"),
        },
        // 运维监控-告警中心
        {
          path: "/warning-center",
          name: "Warning-Center",
          meta: {
            keepAlive: true,
            label: "运维监控-规则引擎",
            desc: "根据设备上报数据，规则引擎可以判断并发起告警通知或联动",
            title: "规则引擎",
            isBack: false,
          },
          component: () =>
            import("@/views/dashboard/operationControl/warningCenter/Index"),
        },
        // 运维监控-编辑规则
        {
          path: "/warning-center/edit-rules",
          name: "Edit-Rules",
          meta: {
            label: "规则详情",
            desc: "根据设备上报数据，规则引擎可以判断并发起告警通知或联动",
            isBack: true,
            title: "规则详情",
            parentRout: {
              label: "运维监控-规则引擎",
              path: "/warning-center",
            },
          },
          component: () =>
            import("@/views/dashboard/operationControl/warningCenter/EditRules"),
        },
        // 增值服务-API调试
        {
          path: "/api-debug",
          name: "Api-Debug",
          meta: {
            label: "增值服务-API调试",
            desc: "",
            isBack: false,
            title: "API调试",
          },
          component: () =>
            import("@/views/dashboard/incrementService/apiDebug/Index"),
        },
        // 增值服务-LBS位置服务
        {
          path: "/lbs-place",
          name: "Lbs-Place",
          meta: {
            label: "增值服务-LBS位置服务",
            desc: "",
            isBack: false,
            title: "LBS位置服务",
          },
          component: () =>
            import("@/views/dashboard/incrementService/lbsplace/Index"),
        },
        // 增值服务-设备位置
        {
          path: "/lbs-place/equip-site",
          name: "Equip-Site",
          meta: {
            label: "设备位置",
            desc: "",
            isBack: true,
            title: "设备位置",
            parentRout: {
              label: "增值服务-LBS位置服务",
              path: "/lbs-place",
            },
          },
          component: () =>
            import("@/views/dashboard/incrementService/lbsplace/EquipSite"),
        },
        //视频接入-视频监控
        {
          path: "/video-monitoring",
          name: "video-monitoring",
          meta: {
            label: "视频接入-视屏监控",
            desc: "提供对视频流的播放查看以及转发配置",
            isBack: false,
            title: "视屏监控",
          },
          component: () =>
            import("@/views/dashboard/videoAccess/videoMonitoring/Index"),
        },
      ],
    },
    // 项目管理
    {
      path: "/project-manage",
      name: "Project",
      component: () => import("@/views/dashboard/projectManagement/Index"),
    },
    // 我的工单
    {
      path: "/order",
      name: "Order",
      meta: {
        label: "设备管理-产品详情",
        desc: "设备管理-产品详情",
      },
      component: () => import("@/views/dashboard/myOrder/Order"),
      redirect: "/order/index",
      children: [
        {
          path: "/order/index",
          name: "Index",
          component: () => import("@/views/dashboard/myOrder/Index"),
        },
        // 提交新工单
        {
          path: "/order-submit",
          name: "Order-Submit",
          component: () => import("@/views/dashboard/myOrder/Submit"),
        },
      ],
    },
    // 开发文档
    {
      path: "/file",
      name: "File",
      component: () => import("@/views/dashboard/document/Index"),
    },
    // 账号信息
    {
      path: "/account",
      name: "Order",
      component: () => import("@/views/account/Account"),
      redirect: "/account/info",
      children: [
        // 账号信息
        {
          path: "/account/info",
          name: "Index",
          component: () => import("@/views/account/accountInfo/Index"),
        },
        // 安全设置
        {
          path: "/account/safeSet",
          name: "Index",
          component: () => import("@/views/account/safeSet/Index"),
        },
        // 子账户设置
        {
          path: "/account/subAccount",
          name: "Index",
          component: () => import("@/views/account/subAccountSet/Index"),
        },
      ],
    },
    // 项目授权管理
    {
      path: "/project",
      name: "Order",
      component: () => import("@/views/project/Project"),
      redirect: "/project",
      children: [
        // 项目信息
        {
          path: "/project",
          name: "Index",
          component: () => import("@/views/project/projectChoose/Index"),
        },
        // 设备列表
        {
          path: "/projectEquip-list",
          name: "Project-List",
          component: () =>
            import("@/views/project/projectEquip/equipList/Index"),
        },
        // 设备信息
        {
          path: "/projectEquip-list/details",
          name: "Equip-Details",
          component: () =>
            import("@/views/project/projectEquip/equipList/ProjectDetails"),
          props: (route) => ({ query: route.query.id }),
        },
        // 分组管理
        {
          path: "/equip-management",
          name: "Equip-Management",
          component: () =>
            import(
              "@/views/project/projectEquip/groupManagement/GroupManagement"
            ),
        },
        // 分组信息
        {
          path: "/equip-management/details",
          name: "Group-Details",
          component: () =>
            import("@/views/project/projectEquip/groupManagement/GroupDetails"),
          props: (route) => ({ query: route.query.id }),
        },
      ],
    },
  ];