import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import '@/styles/reset.scss'
import 'ant-design-vue/dist/antd.css'
//字体图标样式
import './assets/fonts/iconfont.css'
// markdown样式
import 'github-markdown-css'
// 代码高亮
import 'highlight.js/styles/github.css'
// 全局过滤
import * as filters from '@/utils/filters'
//兼容m3u8视频格式播放
const hls =require("videojs-contrib-hls")
Vue.use(hls)
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
Vue.use(Antd)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
