import Axios from "axios"
import { message } from "ant-design-vue"
// Vue.prototype.$message = message
// 创建axios实例
const server = Axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // withCredentials: true,
    timeout: 50000 // 请求超时时间
})

// 添加请求拦截器
server.interceptors.request.use(
    res => {
        const token = localStorage.getItem('token')
        if (token) {
            res.headers.Authorization = `login ${token}`
        }
        return res
    },
    error => {
        message.error(error.errorMsg)
        return Promise.reject(error)
    }
)

// 添加响应拦截器
server.interceptors.response.use(
    response => {
        // 请求成功返回的数据
        let data = response.data
        if (data.errorCode === 200) {
            // 成功
            return data
        } else {
            // 失败
            message.error(data.errorMsg)
            return Promise.reject ('验证码填写错误！')
        }
    },
    (error) => {
        message.error(error.errorMsg)
        return Promise.reject ('验证码填写错误！')
    }
)

export default server

