// 产品/设备列表节点类型
export function nodeTypeFilter (type) {
    const nodeTypeHandlers = {
        1: '直连设备',
        2: '网关设备',
        3: '子设备'
    }
    return nodeTypeHandlers[type] || '-'
}

// 设备列表设备状态
export function statusTypeFilter (type) {
    const statusHandlers = {
        0: '未激活',
        1: '在线',
        2: '离线',
        3: '未知'
    }
    return statusHandlers[type] || '-'
}

// 设备列表创建方式
export function sourceTypeFilter (type) {
    const sourceHandlers = {
        0: '人工注册',
        1: '自动注册'
    }
    return sourceHandlers[type] || '-'
}

// 实例详情服务状态
export function caseStatusTypeFilter (type) {
    const statusHandlers = {
        0: '离线',
        1: '在线'
    }
    return statusHandlers[type] || '-'
}

// 添加自定义功能点功能类型
export function functionTypeFilter (type) {
    const functionTypeHandlers = {
        'properties': '属性',
        'events': '事件',
        'services': '服务'
    }
    return functionTypeHandlers[type] || '-'
}

// 添加自定义功能点功能类别
export function functionCataFilter (type) {
    const functionCataHandlers = {
        'st': '标准',
        'u': '自定义',
        's': '系统'
    }
    return functionCataHandlers[type] || '-'
}

// 添加自定义功能点读写类型
export function rwTypeFilter (type) {
    const rwTypeHandlers = {
        'rw': '读写',
        'r': '读'
    }
    return rwTypeHandlers[type] || '-'
}

// 添加自定义功能点事件类型
export function eventsTypeFilter (type) {
    const eventsTypeHandlers = {
        'info': '信息',
        'alert': '告警',
        'error': '故障'
    }
    return eventsTypeHandlers[type] || '-'
}

// 告警等级
export function warningLevelFilter (type) {
    const warningLevelHandlers = {
        0: '普通',
        1: '严重',
        2: '紧急'
    }
    return warningLevelHandlers[type] || '-'
}

// 告警状态
export function warningStatusFilter (type) {
    const warningStatusHandlers = {
        0: '未处理',
        1: '已处理'
    }
    return warningStatusHandlers[type] || '-'
}

// 启用状态
export function startStatusFilter (type) {
    const startStatusHandlers = {
        0: '禁用',
        1: '启用'
    }
    return startStatusHandlers[type] || '-'
}
