import request from '@/api/loginConfig/index'

// 登录认证
export function login(identifier, imgCode, loginType, smsCode, sessionId) {
    return request({
        url: '/auth/login',
        method: 'post',
        data: {
            identifier,
            imgCode,
            loginType,
            smsCode
        },
        params: {
            sessionId
        }
    })
}

// 获取短信验证码
export function getCaptcha(phone, picCaptcha, sessionId) {
    return request({
        url: '/captcha/sms',
        method: 'get',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        params: {
            cellphone: phone,
            imgCode: picCaptcha,
            sessionId: sessionId
        }
    })
}

// 获取sessionId
export function getSessionId() {
    return request({
        url: '/captcha/sessionId',
        method: 'get'
    })
}

