import { login } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const getDefaultState = () => {
    return {
        token: getToken(),
        roles: []
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_INFO: (state, info) => {
        state = Object.assign(state, info)
    }
}

const actions = {
    login({ commit }, logInfo) {
        const { identifier, imgCode, loginType, smsCode, sessionId } = logInfo
        return new Promise((resolve, reject) => {
            login(identifier, imgCode, loginType, smsCode, sessionId).then(response => {
                console.log(response)
                const { data } = response
                commit('SET_TOKEN', data.token)
                setToken(data.token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }

    /* logout({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            removeToken() // must remove  token  first
            resetRouter()
            commit('RESET_STATE')
            dispatch('tagsView/delAllViews', null, { root: true })

            resolve()
        }).catch(error => {
            reject(error)
        })
    }, */

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
