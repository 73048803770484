import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routes/index.js'
import { doLogin, getUserInfo } from "sso-login-logic"

Vue.use(VueRouter);



const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to.path, from.path, "path")
  const res = await doLogin({
    toPath: to.path,
    baseUrl: process.env.VUE_APP_LOGINBASE_URL,
    version: 2
  })
  const { release, token, user, path } = res
  console.log(res)
  // if (!Object.keys(store.getters.userInfo).length) {
  //   if (getUserInfo() !== "undefined") {
  //     store.commit("user/SET_USERINFO", JSON.parse(getUserInfo()))
  //   } else if (user) {
  //     store.commit("user/SET_USERINFO", user)
  //   } else {
  //     console.log("没有用户信息")
  //   }
  // }
  if (release) {
    if (user) {
      next(path)
    } else {
      next()
    }
  } else {
    next(false)
    if (path) {
      window.location.href = path
    }
  }
})

export default router;
