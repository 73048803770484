import Cookies from 'js-cookie'
const TokenKey='userToken'

export function getToken () {
    return Cookies.get(TokenKey)
}

export function setToken (token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken () {
    return Cookies.remove(TokenKey)
}

export function setCookie (name, value, timer=1) {
    let Days = timer
    let exp  = new Date()
    exp.setTime(exp.getTime() + Days*24*60*60*1000)
    document.cookie = name + '=' + escape(value) + ';expires='+ exp.toGMTString()
}

export function getCookie (name) {
    let arr = document.cookie.match(new RegExp('(^| )'+name+'=([^;]*)(;|$)'))
    if(arr !== null)
        return unescape(arr[2])
    return null
}
